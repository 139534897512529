import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// import 'bootstrap/dist/css/bootstrap.min.css';
// Importing Sass with Bootstrap CSS
import './style/styles.scss';

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);