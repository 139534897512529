import React from 'react';
import Contact from './Contact';

const GetInvolved = () => {
    return (
        <main>
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Get Involved</h2>
                </div>

                <div>
                    {/* three panels as responsive columns */}
                    <div className="row">
                        <div className="col-sm">
                            <h2>App Development + Beta Testing</h2>
                            <p>This position requires constantly developing and updating SafeSpace's app based on the feedback we receive from the feedback from beta-testing and users. Also requires frequent debugging and ensuring everything runs smoothly! Experience in coding, app development, and graphic design would be preferred, but not required!</p>
                        </div>
                        <div className="col-sm">
                            <h2>Social Media + Marketing</h2>
                            <p>This position requires maintaining SafeSpace social media accounts - primarily Instagram and Facebook - and creating weekly posts on Canva regarding any events we recently participated in, statistics on this issue, positive affirmations, important resources, etc.. Experience in graphic design and social media management would be helpful, but not required!</p>
                        </div>
                        <div className="col-sm">
                            <h2>Planning Community Awareness Events</h2>
                            <p>This position requires lots of event planning and taking initiative in reaching out to the domestic violence organizations we are partnered with. Includes planning workshops, panels, presentations, fundraisers, etc., that SafeSpace's team can participate in and collaborating with our partners in executing them and maintaining the team's budget.</p>
                        </div>

                    </div>
                </div>


            </div>
            <Contact />
        </main>
    );
};

export default GetInvolved;