import React from 'react';

const Contact: React.FC = () => {
    return (
        <section className="page-section" id="contact">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Contact Us</h2>
                    {/* email & instagram links with fontawesome icons */}
                    <div className="d-flex justify-content-center">
                        <a href="mailto:safespace525@gmail.com" className="mx-3">
                            <i className="fa-solid fa-envelope"></i>
                        </a>
                        <a href="https://www.instagram.com/_safespace.app/" className="mx-3">
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;