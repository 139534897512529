import React from 'react';

const Resources = () => {
    return (
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Important Resources</h2>
            </div>
            <p>Below are a list of valuable resources for survivors to recover and heal from their traumatic incidents. The following resources were carefully chosen by our team based on our experience with their credibility and efficiency in providing timely and personalized care.</p>

            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Resource</th>
                            <th scope="col">Description</th>
                            <th scope="col">Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Washington State Coalition Against Domestic Violence (WSCADV)</td>
                            <td>WSCADV is the leading voice to end domestic violence in Washington State. We improve how communities respond to domestic violence and are working to create a world where all people can live and love without fear.</td>
                            <td><a href="https://wscadv.org/">https://wscadv.org/</a></td>
                        </tr>
                        <tr>
                            <td>LifeWire</td>
                            <td>LifeWire supports survivors of domestic violence on their path towards safety, stability, and healing. LifeWire work to end domestic violence by changing community beliefs, attitudes, and behaviors</td>
                            <td><a href="https://www.lifewire.org/">https://www.lifewire.org/</a></td>
                        </tr>
                        <tr>
                            <td>National Domestic Violence Hotline</td>
                            <td>Everyone deserves relationships free from domestic violence. When you're ready, the hotline will listen with confidential support 24/7/365.</td>
                            <td><a href="https://www.thehotline.org/">https://www.thehotline.org/</a></td>
                        </tr>
                        <tr>
                            <td>New Beginnings: Ending Domestic Violence</td>
                            <td>New Beginnings empowers survivors and mobilizes community awareness and action to end domestic violence.</td>
                            <td><a href="https://newbegin.org/">https://newbegin.org/</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Resources;