import React from 'react';

const Team: React.FC = () => {
    return (
        <section className="page-section bg-light" id="team">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Meet the Team</h2>
                    <h3 className="section-subheading text-muted">The Faces behind Our Work</h3>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src='img/team/gauri.webp' alt="..." />
                            <h4>Gauri Gupta</h4>
                            <p className="text-muted">Co-Founder</p>
                            <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/gauri-gupta-611464234/" aria-label="Gauri Gupta LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src='img/team/ananya.webp' alt="..." />
                            <h4>Ananya Aatreya</h4>
                            <p className="text-muted">Co-Founder: Technical Developer</p>
                            <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/ananya-aatreya/" aria-label="Ananya Aatreya LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src='img/team/ward.webp' alt="..." />
                            <h4>Ward Urion</h4>
                            <p className="text-muted">Advisor, WSCADV Preventionist</p>
                            <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/urionsearch/" aria-label="Ward Urion LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src='img/team/karina.webp' alt="..." />
                            <h4>Karina Tamayo</h4>
                            <p className="text-muted">Advisor, LifeWire Preventionist</p>
                            <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/karina-y-tamayo/" aria-label="Karina Tamayo LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 mx-auto text-center"><p className="large text-muted">The good work My SafeSpace does is all thanks to our dedicated staff members. Passionate and skilled, they work tirelessly to maximize our impact. Get to know some of the people who make My SafeSpace possible through their commitment, creativity and drive.</p></div>
                </div>
            </div>
        </section>
    );
};

export default Team;