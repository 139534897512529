import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScrollSpy } from 'bootstrap';
import Header from './Header';
import Footer from './Footer';
import NavBar from './NavBar';
import Resources from './Resources';
import Home from './Home';
import GetInvolved from './GetInvolved';

const App = () => {
  var navbarShrink = function () {
    const navbarCollapsible = document.body.querySelector('#mainNav');
    if (!navbarCollapsible) {
      return;
    }
    if (window.scrollY === 0) {
      navbarCollapsible.classList.remove('navbar-shrink')
    } else {
      navbarCollapsible.classList.add('navbar-shrink')
    }

  };

  useEffect(() => {
    // Shrink the navbar 
    navbarShrink();

    // Shrink the navbar when page is scrolled
    document.addEventListener('scroll', navbarShrink);

    //  Activate Bootstrap scrollspy on the main nav element
    const mainNav = document.body.querySelector('#mainNav');
    if (mainNav) {
      new ScrollSpy(document.body, {
        target: '#mainNav',
        rootMargin: '0px 0px -40%',
      });
    };

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('scroll', navbarShrink);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <Router>
      <div id="page-top" className="scroll-container" onScroll={navbarShrink}>
        <NavBar navItems={[
          { label: 'Home', href: '/' },
          { label: 'Portfolio', href: '/#portfolio' },
          { label: 'About', href: '/#about' },
          { label: 'Team', href: '/#team' },
          { label: 'Resources', href: '/resources' },
          { label: 'Contact', href: '/#contact' },
        ]}
        />
        <Header title="My SafeSpace" />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/get-involved' element={<GetInvolved />} />
        </Routes>

        <Footer companyName="My SafeSpace" />
      </div>
    </Router>
  );
};

export default App;
