import React from 'react';

type PortfolioItem = {
    date: string;
    title: string;
    description: string;
    image: string;
}

type PortfolioProps = {
    portfolioItems: PortfolioItem[];
};

const Portfolio = ({ portfolioItems }: PortfolioProps) => {
    return (
        <section className="page-section" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Our Work</h2>
                    <h3 className="section-subheading text-muted">Our goal with SafeSpace is not merely to promote an app, but rather, create a culture of activism, where young people are comfortable using their voices to speak out about the injustices surrounding youth sexual assault and domestics violence. Below are some of the community awareness activities our team has done:</h3>
                </div>
                <ul className="timeline">
                    {portfolioItems.map((item, index) => (
                        <li key={index} className={index % 2 === 1 ? "timeline-inverted" : ""}>
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={item.image} alt={item.title} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>{item.date}</h4>
                                    <h4 className="subheading">{item.title}</h4>
                                </div>
                                <div className="timeline-body"><p className="text-muted">{item.description}</p></div>
                            </div>
                        </li>
                    ))}
                    <li>
                        <div className="timeline-image">
                            <a className="nav-link" href='/get-involved'>
                                <h4>
                                    Be Part
                                    <br />
                                    Of Our
                                    <br />
                                    Story!
                                </h4>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Portfolio;
export type { PortfolioItem };