type NavItem = {
    label: string;
    href: string;
};

type NavBarProps = {
    navItems: NavItem[];
};

const NavBar = ({ navItems }: NavBarProps) => {

    const hideMenu = () => {
        const navbarToggler: HTMLButtonElement = document.body.querySelector('.navbar-toggler')!;
        if (window.getComputedStyle(navbarToggler).display !== 'none') {
            navbarToggler.click();
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div className="container">
                <span><a className="navbar-brand" href="/">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#000000"  // This sets the default fill color
                    >
                        <image xlinkHref='img/logo.svg' width="24" height="24" />
                    </svg></a>
                    <a className="navbar-brand" href="/">My SafeSpace</a></span>
                {/* <img src={logo} alt="..." /></a> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars ms-1"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        {navItems.map((item, index) => (
                            <li className="nav-item" key={index} onClick={hideMenu}>
                                <a className="nav-link" href={item.href}>{item.label}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
