import React from 'react';
import Contact from './Contact';
import Team from './Team';
import About from './About';
import Portfolio, { PortfolioItem } from './Portfolio';

const portfolioItems: PortfolioItem[] = [
    {
        date: "2022.08.22",
        title: "New Beginnings Youth Retreat",
        description: "Led an interactive and engaging workshop regarding technological-facilitated abuse to a grouo of over 25 teenagers at New Beginnings summer retreat.",
        image: 'img/portfolio/2022.08.22.webp'
    },
    {
        date: "2022.09.19",
        title: "WSCADV Statewide Conference",
        description: "Our team gave a brief 15 minute presentation to a group of statewide preventionist at this 1-week conference regarding our advocacy work with SafeSpace and how to best support youth in our state.",
        image: 'img/portfolio/2022.09.19.webp'
    },
    {
        date: "2022.10.18",
        title: "WSCADV Facebook Live Event",
        description: "Invited by WSCADV to serve as key panelists for their Facebook live event to commemorate Domestic Violence Awareness month in October, where we spoke regarding our work with SafeSpace and the necessity to bring a youth perspective to this pressing issue.",
        image: 'img/portfolio/2022.10.18.webp'
    },
    {
        date: "2022.11.16",
        title: "Workshop at the University of Washington Bothell",
        description: "Led an interactive workshop to college students at UW-Bothell regarding the importance of staying safe online and tips to protect yourself from online predators.",
        image: 'img/portfolio/2022.11.16.webp'
    },
    {
        date: "2023.01.16",
        title: "TECCnically Speaking Podcast",
        description: "Planned, recorded, and published a 30-minute podcast in partnership with LifeWire, regarding the correlation between technology and survivor-support as well as portrayal of abuse in the media.",
        image: 'img/portfolio/2023.01.16.webp'
    },
    {
        date: "2023.02.04",
        title: "Confronting Domestic Violence Interview",
        description: "Partnered with Agape Garcia, founder of CDV Inc., to be featured on a 30-minute interview for their TV show. We discussed our advocacy work with SafeSpace and our future plans to incorporate youth into our mission.",
        image: 'img/portfolio/2023.02.04.webp'
    },
    {
        date: "2023.02.15",
        title: "Presenting to the Bellevue Youth Council",
        description: "SafeSpace's team presented our work to the Bellevue Youth Council, which is the largest youth council in the nation consisting of 150+ youth. Following our presentation, we received an overwhelmingly large interest in several youth interested in helping us with our work and marketing.",
        image: 'img/portfolio/2023.02.15.webp'
    }
]

const Home = () => {
    return (
        <main>
            <Portfolio portfolioItems={portfolioItems} />
            <About />
            <Team />
            <Contact />
        </main>
    );
};

export default Home;