import React from 'react';

const About: React.FC = () => {
    return (
        <section className="page-section" id="about">
            <div className="container-lg">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">About Us</h2>
                    <h3 className="section-subheading text-muted">After witnessing a series of student-led protests within our high school and various other schools throughout the district regarding the lack of action taken by the school administrators regarding assaulat within their campuses, our team was compelled to take action and to support teenage survivors. After doing extensive research and discussing with established domestic violence organizations within the we believed that creating an app would be the best way to support teens because of its revolutionizing potential to address these global issues.</h3>
                </div>
                <div>
                    <h4>We believe all teenagers deserve the necessary supportive resources to confront this stigmatized issues and to increase the emphasis on safety and advocacy.</h4>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col-lg-6 align-self-start">
                        </div>
                        <div className="col-lg-6 align-self-start">
                            <div className="card">
                                <img className="card-img-top" src='img/mission.webp' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Our Mission</h5>
                                    <p className="card-text">SafeSpace's mission is to provide survivors of teen dating violence or domestic violence the necessary supportive resources in a concealed manner.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 align-self-end">
                            <div className="card">
                                <img className="card-img-top" src='img/vision.webp' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Our Vision</h5>
                                    <p className="card-text">SafeSpace is humble beginning to the work that needs to be done to drastically reduce the occurrence of assault within our communities. It was created to support survivors and provide them a lifeline in resources to keep them connected to a community. My hope is our contribution of SafeSpace with spur the wheels of ingenuity to build stronger network of resources and we hope that your story of action inspires other youth to use their strengths to solve problems that may appear bigger than they are.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-start">
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default About;