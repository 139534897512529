import React from 'react';

type HeaderProps = {
    title: string;
};

const Header: React.FC<HeaderProps> = ({ title }) => {
    return (
        <header className="masthead" style={{ backgroundImage: `url('img/my-safespace2.webp')` }}>
        </header>
    );
};

export default Header;
